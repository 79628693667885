import { EntityOrigin, Species } from '~graphql/generated/graphql'
import {
    AmphibianIcon,
    BirdIcon,
    CatIcon,
    CowIcon,
    DeerIcon,
    DogIcon,
    DonkeyIcon,
    FishIcon,
    GoatIcon,
    HorseIcon,
    LionIcon,
    LizardIcon,
    MarsupialIcon,
    PawIcon,
    PigIcon,
    PocketPetIcon,
    PrimateIcon,
    RabbitIcon,
    ReptileIcon,
    RodentIcon,
    SheepIcon,
    SnakeIcon,
    TurtleIcon,
    WolfIcon,
} from '~components/shared/animal-icons'
import { Dispatch, SetStateAction } from 'react'

export type SetStateFn<T> = Dispatch<SetStateAction<T>>

export enum LocalStorageKeys {
    authToken = 'authToken',
    selectedClinic = 'selectedClinic',
    selectedOrganization = 'selectedOrganization',
    hasSeenDownloadPage = 'hasSeenDownloadPage',
    dailyHuddle = 'dailyHuddle',
    hasSeenTutorialPage = 'hasSeenTutorialPage',
    showInactivePhones = 'showInactivePhones',
    onlyActiveNotificationTemplates = 'onlyActiveNotificationTemplates',
}

export const getEmptyAvailabilityState = () => {
    const EmptyAvailabilityState = Object.freeze({
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    })

    return { ...EmptyAvailabilityState }
}

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

export const FALLBACK_AVATAR =
    'https://firebasestorage.googleapis.com/v0/b/chckup-server-dev.appspot.com/o/default%20avatar.jpeg?alt=media&token=95c9943d-9cd8-445a-93b6-73106d23c079'

export const speciesIconMap: {
    [key: string]: React.FC<{ className?: string }>
} = {
    [Species.Amphibian]: AmphibianIcon,
    [Species.Bird]: BirdIcon,
    [Species.Cat]: CatIcon,
    [Species.Cow]: CowIcon,
    [Species.Deer]: DeerIcon,
    [Species.Dog]: DogIcon,
    [Species.Donkey]: DonkeyIcon,
    [Species.Ferret]: RodentIcon,
    [Species.Fish]: FishIcon,
    [Species.Goat]: GoatIcon,
    [Species.Horse]: HorseIcon,
    [Species.Lion]: LionIcon,
    [Species.Lizard]: LizardIcon,
    [Species.Marsupial]: MarsupialIcon,
    [Species.Panther]: CatIcon,
    [Species.Pig]: PigIcon,
    [Species.PocketPet]: PocketPetIcon,
    [Species.Primate]: PrimateIcon,
    [Species.Rabbit]: RabbitIcon,
    [Species.Reptile]: ReptileIcon,
    [Species.Rodent]: RodentIcon,
    [Species.Sheep]: SheepIcon,
    [Species.Snake]: SnakeIcon,
    [Species.Turtle]: TurtleIcon,
    [Species.Wolf]: WolfIcon,
    [Species.Other]: PawIcon,
}

export enum SupabaseEntities {
    Appointment = 'Appointment',
    Client = 'Client',
    Clinic = 'Clinic',
    Clinician = 'Clinician',
    Room = 'Room',
    ConnectInstallation = 'ConnectInstallation',
    Patient = 'Patient',
    User = 'User',
    Organization = 'Organization',
    PmsApptType = 'PmsApptType',
}

export const getSupabaseLink = (entity: SupabaseEntities, id: string) => {
    return `https://app.supabase.com/project/${
        process.env.SUPABASE_PROJECT_ID
    }/editor/${getTableId(entity)}/?filter=id%3Aeq%3A${id}`
}

// map each environment to dictionary of entity types to table ids
const getTableId = (entity: SupabaseEntities) => {
    if (process.env.NODE_ENV === 'production') {
        return ProdTableIds[entity]
    } else if (process.env.NODE_ENV === 'staging') {
        return StagingTableIds[entity]
    } else {
        return DevTableIds[entity]
    }
}

export type PageSizeType = 10 | 25 | 50 | 100

export const PAGE_SIZES: PageSizeType[] = [10, 25, 50, 100]

const DevTableIds: { [key in SupabaseEntities]: string } = {
    Appointment: '2034811',
    Client: '2034819',
    Clinic: '2034971',
    Clinician: '2034963',
    Room: '2035067',
    ConnectInstallation: '2034980',
    Patient: '2034828',
    User: '2035092',
    Organization: '2035025',
    PmsApptType: '',
}

const StagingTableIds: { [key in SupabaseEntities]: string } = {
    Appointment: 'staging_appointments',
    Client: 'staging_clients',
    Clinic: 'staging_clinics',
    Clinician: 'staging_clinicians',
    Room: '',
    ConnectInstallation: 'staging_connect_installations',
    Patient: 'staging_patients',
    User: '',
    Organization: 'staging_organizations',
    PmsApptType: '',
}

const ProdTableIds: { [key in SupabaseEntities]: string } = {
    Appointment: '29032',
    Client: '29049',
    Clinic: '29141',
    Clinician: '29133',
    Room: '29205',
    ConnectInstallation: '29149',
    Patient: '29173',
    User: '29229',
    Organization: '29165',
    PmsApptType: '',
}

// Array.prototype.map = function <T extends Record<string, unknown>>(item: T) {
//     return this.map(i => i[item])
// }

export const CHCKUP_ORIGINS: EntityOrigin[] = [
    EntityOrigin.ChckupWidget,
    EntityOrigin.ChckupWebsite,
    EntityOrigin.ChckupDashboard,
]

export const DEFAULT_TIME_ZONE = 'America/New_York'
