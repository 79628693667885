import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import { StripeAccountRequirements } from '~graphql/generated/graphql'
import { format } from 'date-fns'

interface Props {
    isOnboarded: boolean
    requirements?: StripeAccountRequirements | null
    future_requirements?: StripeAccountRequirements | null
}

export default function StripeAccountStatusText(props: Props) {
    return (
        <Box mb={4}>
            <Text fontWeight="semibold" color="gray.900">
                {getStatusText(props)}
            </Text>
            <Text fontSize="sm" color="gray.500">
                {getStatusDescription(props)}
            </Text>
        </Box>
    )
}

function getStatusText({
    isOnboarded,
    requirements,
    future_requirements,
}: Props) {
    const { pending_verification, currently_due } = requirements || {}
    const {
        currently_due: futureCurrentlyDue,
        pending_verification: futurePendingVerifications,
    } = future_requirements || {}

    if (!!futurePendingVerifications?.length) {
        return 'Pending verification from Stripe'
    } else if (!!futureCurrentlyDue?.length) {
        return 'Action required in your Stripe dashboard'
    } else if (!isOnboarded) {
        if (!!pending_verification?.length) {
            if (!!currently_due?.length) {
                return 'Finish setting up your account'
            }
            return 'Pending verification from Stripe'
        }

        return 'Payments are disabled'
    }

    return 'Payments enabled'
}

function getStatusDescription({
    isOnboarded,
    requirements,
    future_requirements,
}: Props) {
    const { pending_verification, currently_due } = requirements || {}
    const {
        pending_verification: futurePendingVerifications,
        currently_due: futureCurrentlyDue,
        current_deadline,
    } = future_requirements || {}

    if (!!futurePendingVerifications?.length) {
        return 'Stripe is reviewing your information. Your account is still active while they review.'
    } else if (!!futureCurrentlyDue?.length) {
        return `Stripe has requested additional information to keep your account verified, please fill out this additional info. ${current_deadline ? `Your account is still active until ${format(current_deadline, 'PP')}.` : '}'}`
    }
    if (!isOnboarded) {
        if (!!pending_verification?.length) {
            if (!!currently_due?.length) {
                return 'You still have some fields that need to be filled out to complete your onboarding.'
            }
            return 'The information you submitted is being reviewed and payments should be enabled shortly.'
        } else {
            return 'Visit the Stripe dashboard to setup your account.'
        }
    } else {
        return 'Your account with Stripe is active and you can accept payments!'
    }
}
