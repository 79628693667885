import { BoxProps } from '@chakra-ui/react'
import React from 'react'
import SharedSwitch, {
    SharedSwitchProps,
} from '~components/shared/form/shared-switch'
import SharedStack, {
    SharedStackProps,
} from '~components/shared/wrappers/shared-stack'
import { useUserContext } from '~config/user-context'
import SettingAdminTag from './setting-admin-tag'

interface Props extends SharedSwitchProps {
    requiresAdmin?: boolean
    containerOverrides?: SharedStackProps
}

export default function SettingSwitch({
    requiresAdmin,
    containerOverrides,
    ...sharedSwitchProps
}: Props) {
    const { isAdmin } = useUserContext()

    const SHARED_PROPS: BoxProps = {
        display: 'flex',
        flexDir: 'row',
        w: 'full',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    }

    if (requiresAdmin && !isAdmin) return null

    return (
        <SharedStack
            direction={{ base: 'column', md: 'row' }}
            align="center"
            {...containerOverrides}
        >
            {requiresAdmin && <SettingAdminTag />}
            <SharedSwitch
                size="md"
                showActiveLabel
                labelProps={{ fontSize: 'md' }}
                containerProps={SHARED_PROPS}
                {...sharedSwitchProps}
            />
        </SharedStack>
    )
}
