import React from 'react'
import { TabPanel } from '@chakra-ui/tabs'
import { Box, Flex, Text } from '@chakra-ui/layout'

import {
    ClinicFieldsFragment,
    useStripeAccountInfoQuery,
} from '~graphql/generated/graphql'
import LoadingView from '~components/shared/loading-view'
import StripeAccountStatusText from './status-text'
import EmptyState from '~components/shared/empty-state'
import StripeDashboardActionButton from './payment-action-button'

export default function PaymentSettingsPanel({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    return (
        <TabPanel>
            <PaymentSettingsPanelContent clinic={clinic} />
        </TabPanel>
    )
}

function PaymentSettingsPanelContent({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    const { data, loading } = useStripeAccountInfoQuery({
        variables: {
            clinicId: clinic?.id || '',
            callbackUrls: {
                returnUrl: `${process.env.GATSBY_DASHBOARD_URL}/settings?feature=payments`,
                refreshUrl: `${process.env.GATSBY_DASHBOARD_URL}/settings?feature=payments`,
            },
        },
    })

    if (loading) {
        return <LoadingView py={8} />
    }

    if (!data?.stripeAccountInfo) {
        return (
            <EmptyState
                title="Can't load payment settings"
                subtitle="Failed to load payment settings"
            />
        )
    }

    const {
        is_onboarded: isOnboarded,
        requirements,
        future_requirements,
    } = data?.stripeAccountInfo

    return (
        <Box>
            <Flex
                py={4}
                w="full"
                flexDir="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <StripeAccountStatusText
                    isOnboarded={isOnboarded}
                    requirements={requirements}
                    future_requirements={future_requirements}
                />
                <StripeDashboardActionButton
                    accountInfo={data.stripeAccountInfo}
                />
            </Flex>
            {/* <StripeRequirementsBlock requirements={requirements} /> */}
        </Box>
    )
}
