import React from 'react'
import { WarningIcon } from '@chakra-ui/icons'
import { Box, HStack, Text, BoxProps, TextProps } from '@chakra-ui/layout'
import {
    Popover,
    PopoverTrigger,
    PopoverArrow,
    PopoverContent,
    PopoverBody,
} from '@chakra-ui/popover'
import { Menu, MenuButton, MenuList, MenuProps } from '@chakra-ui/menu'

import SharedLabel from './shared-label'
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Button, ButtonProps } from '@chakra-ui/button'
import { Flex, MenuListProps, useDisclosure } from '@chakra-ui/react'
import GradientDivider from '../gradient-divider'
import MenuItem from '../menu/menu-item'
import { FaCheck } from 'react-icons/fa6'

export interface MenuSelectProps extends ButtonProps {
    label?: string
    labelProps?: TextProps
    labelEnhancer?: React.ReactElement
    moreInfo?: React.ReactElement
    containerProps?: BoxProps
    placeholder?: string
    textModification?: (e: string) => string
    options?: string[] | { value: string; rightElement?: React.ReactElement }[]
    renderValue?: (value: string) => React.ReactNode
    renderOption?: (option: string) => React.ReactNode
    value?: string
    setValue?: (value: string, index?: number) => void
    listMaxHeight?: string
    listHeader?: React.ReactNode
    listOverrides?: Omit<MenuListProps, 'maxH' | 'overflowY'>
    menuOverrides?: Omit<MenuProps, 'isOpen' | 'onClose' | 'children'>
    error?: React.ReactNode
}

export default function MenuSelect({
    label,
    labelProps,
    labelEnhancer,
    moreInfo,
    containerProps,
    placeholder,
    textModification,
    options,
    renderValue,
    renderOption,
    value,
    setValue = () => {},
    listMaxHeight,
    listHeader,
    listOverrides,
    menuOverrides,
    error,
    ...props
}: MenuSelectProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box {...containerProps}>
            {(label || moreInfo) && (
                <Box mb={1}>
                    {label && (
                        <SharedLabel mb={1} fontSize="sm" {...labelProps}>
                            {label}
                        </SharedLabel>
                    )}
                    {!!moreInfo && moreInfo}
                </Box>
            )}
            <HStack alignItems="center">
                <Menu isOpen={isOpen} onClose={onClose} {...menuOverrides}>
                    <MenuButton
                        as={Button}
                        variant="outline"
                        rightIcon={<ChevronDownIcon />}
                        textAlign="left"
                        onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            onOpen()
                        }}
                        {...props}
                    >
                        {renderValue && value ? (
                            renderValue(value)
                        ) : (
                            <HStack spacing={1}>
                                {labelEnhancer}
                                <Text>
                                    {value
                                        ? textModification
                                            ? textModification(value)
                                            : value
                                        : placeholder || 'Select'}
                                </Text>
                            </HStack>
                        )}
                    </MenuButton>
                    <MenuList
                        maxH={listMaxHeight || '296px'}
                        overflowY="auto"
                        {...listOverrides}
                    >
                        {listHeader && (
                            <>
                                <Box px={4}>{listHeader}</Box>
                                <GradientDivider my={1} />
                            </>
                        )}
                        {options &&
                            options.map((o, index) => {
                                const curVal =
                                    typeof o === 'string' ? o : o.value

                                if (renderOption) {
                                    return (
                                        <MenuItem
                                            key={`${o}-${index}`}
                                            icon={
                                                value === curVal
                                                    ? FaCheck
                                                    : undefined
                                            }
                                            onClick={e => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setValue(curVal, index)
                                            }}
                                        >
                                            {renderOption(curVal)}
                                        </MenuItem>
                                    )
                                }

                                return (
                                    <MenuItem
                                        key={`${o}-${index}`}
                                        icon={
                                            value === curVal
                                                ? FaCheck
                                                : undefined
                                        }
                                        onClick={e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            setValue(curVal, index)
                                        }}
                                        w="full"
                                    >
                                        <Flex align="center">
                                            <>
                                                {typeof o === 'string'
                                                    ? ''
                                                    : o.rightElement}
                                                {curVal && textModification
                                                    ? textModification(curVal)
                                                    : o}
                                            </>
                                        </Flex>
                                    </MenuItem>
                                )
                            })}
                    </MenuList>
                </Menu>
                {!!error && (
                    <Popover>
                        <PopoverTrigger>
                            <Box>
                                <WarningIcon boxSize={6} color="yellow.500" />
                            </Box>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>{error}</PopoverBody>
                        </PopoverContent>
                    </Popover>
                )}
            </HStack>
        </Box>
    )
}
