import React from 'react'
import { Icon } from '@chakra-ui/icon'
import { Tooltip } from '@chakra-ui/tooltip'
import { Circle, Square } from '@chakra-ui/layout'

import { PaymentStatus } from '~graphql/generated/graphql'

const PaymentStatusTag: React.FC<{
    status: PaymentStatus
    shouldDisableTooltip?: boolean
    size?: 'sm' | 'md'
}> = ({ status, shouldDisableTooltip, size = 'md' }) => {
    return (
        <Tooltip
            placement="right"
            label={status.capitalize()}
            isDisabled={shouldDisableTooltip}
        >
            <Square
                size={size === 'sm' ? 4 : 5}
                rounded="sm"
                color="white"
                bg={getStatusColor(status)}
            >
                {getStatusIcon(status, size)}
            </Square>
        </Tooltip>
    )
}

function getStatusIcon(status: PaymentStatus, size?: 'sm' | 'md') {
    switch (status) {
        case PaymentStatus.Success:
            return (
                <Icon boxSize={size === 'md' ? 4 : 3}>
                    <path
                        d="M8.69164 20.2051L1.29976 13.3396C0.900079 12.9464 0.900079 12.3088 1.29976 11.9156L3.51217 9.32959C3.91185 8.93633 4.55994 8.93633 4.95962 9.32959L9.3741 13.6117L19.0403 4.29491C19.44 3.9017 20.0881 3.9017 20.4878 4.29491L22.7002 6.47415C23.0999 6.86737 23.0999 7.50492 22.7002 7.89818L10.1391 20.2051C9.73937 20.5983 9.09132 20.5983 8.69164 20.2051Z"
                        fill="currentColor"
                    />
                </Icon>
            )
        case PaymentStatus.Cancelled:
        case PaymentStatus.Failed:
            return (
                <Icon boxSize={size === 'md' ? 4 : 3}>
                    <path
                        d="M18.694 20.6708L20.6676 18.7162C21.1108 18.2772 21.1108 17.5654 20.6676 17.1264L6.79624 3.32926C6.353 2.89025 5.6344 2.89025 5.1912 3.32926L3.3324 5.30661C2.8892 5.74562 2.8892 6.45747 3.3324 6.89649L17.089 20.6708C17.5322 21.1098 18.2508 21.1098 18.694 20.6708Z"
                        fill="currentColor"
                    />
                    <path
                        d="M3.32925 18.694L5.28375 20.6676C5.72276 21.1108 6.43457 21.1108 6.87363 20.6676L20.6707 6.79624C21.1098 6.353 21.1098 5.6344 20.6707 5.1912L18.6934 3.3324C18.2544 2.8892 17.5425 2.8892 17.1035 3.3324L3.32925 17.089C2.89024 17.5322 2.89024 18.2508 3.32925 18.694Z"
                        fill="currentColor"
                    />
                </Icon>
            )
        case PaymentStatus.Pending:
        default:
            return <Circle size={size === 'md' ? 2.5 : 2} bg="white" />
    }
}

function getStatusColor(status: PaymentStatus) {
    switch (status) {
        case PaymentStatus.Success:
            return 'brand.500'
        case PaymentStatus.Pending:
            return 'blue.400'
        case PaymentStatus.Cancelled:
            return 'orange.300'
        case PaymentStatus.Failed:
            return 'red.400'
        default:
            return 'gray.400'
    }
}

export default PaymentStatusTag
