import React, { PropsWithChildren } from 'react'
import { IconType } from 'react-icons'
import { Icon, IconProps } from '@chakra-ui/icons'
import { Text } from '@chakra-ui/layout'
import {
    MenuItem as ChakraMenuItem,
    type MenuItemProps as ChakraMenuItemProps,
} from '@chakra-ui/menu'

export interface MenuItemProps extends Omit<ChakraMenuItemProps, 'icon'> {
    icon?: IconType
    iconProps?: IconProps
}

export default function MenuItem({
    children,
    onClick,
    icon,
    iconProps,
    ...rest
}: PropsWithChildren<MenuItemProps>) {
    return (
        <ChakraMenuItem
            onClick={onClick}
            icon={icon && <Icon as={icon} {...iconProps} />}
            {...rest}
        >
            <Text fontSize="sm" fontWeight="medium">
                {children}
            </Text>
        </ChakraMenuItem>
    )
}
