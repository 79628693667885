import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { Tooltip } from '@chakra-ui/tooltip'
import { Box, Text } from '@chakra-ui/layout'
import { FaBell, FaMobile, FaPhone, FaX } from 'react-icons/fa6'

import {
    GetClientDocument,
    ClientFieldsFragment,
    ClientPhoneFieldsFragment,
    useMakeClientPhonePrimaryMutation,
} from '~graphql/generated/graphql'
import InfoItem from '~components/shared/info-item'
import { formatPhoneIfValid, splitArrayWithFilter } from '~utils/helpers'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SharedHelpText from '~components/shared/form/shared-help-text'
import { useStorage } from '~utils/hooks/use-storage'
import { LocalStorageKeys } from '~config/constants'
import { useLocalStorage } from '~utils/hooks/use-local-storage'
import { Center, Tag } from '@chakra-ui/react'

interface Props {
    client: ClientFieldsFragment
}

export default function ClientPhonesInfo({ client }: Props) {
    const allClientPhones = client.client_phones ?? []

    const numInactive = allClientPhones.filter(phone => !phone.is_active).length

    const [showInactive, setShowInactive] = useLocalStorage<boolean>(
        LocalStorageKeys.showInactivePhones,
        numInactive > 0,
    )

    const [activeClientPhones, inactiveClientPhones] = splitArrayWithFilter(
        allClientPhones,
        phone => phone.is_active || phone.is_primary || phone.is_chckup_primary,
    )

    const clientPhones = showInactive
        ? [...activeClientPhones, ...inactiveClientPhones]
        : activeClientPhones

    const primaryPhone =
        clientPhones.find(phone => phone.is_chckup_primary) || null

    const [makePhonePrimary, { loading }] = useMakeClientPhonePrimaryMutation({
        refetchQueries: [
            {
                query: GetClientDocument,
                variables: { id: client.id },
            },
        ],
        awaitRefetchQueries: true,
    })

    async function handleMakePrimary(phone: ClientPhoneFieldsFragment) {
        await makePhonePrimary({
            variables: {
                clinicId: client.clinic?.id || '',
                clientId: client.id,
                phoneId: phone.id,
            },
        })
    }

    async function handleRemovePrimary(phone: ClientPhoneFieldsFragment) {
        const notPhone = clientPhones.find(p => p.id !== phone.id)

        if (!notPhone) return

        await makePhonePrimary({
            variables: {
                clinicId: client.clinic?.id || '',
                clientId: client.id,
                phoneId: notPhone.id,
            },
        })
    }

    return (
        <InfoItem
            label="Phones"
            info={
                <SharedStack spacing={2}>
                    {allClientPhones.length > 0 ? (
                        clientPhones.map(phone => (
                            <PhoneItem
                                key={phone.id}
                                phone={phone}
                                isPrimary={
                                    primaryPhone
                                        ? primaryPhone?.id === phone.id
                                        : phone.is_primary
                                }
                                isLoading={loading}
                                onClick={handleMakePrimary}
                                onRemove={
                                    clientPhones.length > 1
                                        ? handleRemovePrimary
                                        : undefined
                                }
                            />
                        ))
                    ) : (
                        <SharedHelpText>
                            No phones synced from your Practice Management
                            System
                        </SharedHelpText>
                    )}
                    {numInactive > 0 && (
                        <Button
                            size="xs"
                            variant="link"
                            onClick={() => setShowInactive(!showInactive)}
                        >
                            {showInactive ? 'Hide inactive' : 'Show inactive'}
                        </Button>
                    )}
                </SharedStack>
            }
        />
    )
}

interface PhoneItemProps {
    phone: ClientPhoneFieldsFragment
    isPrimary?: boolean
    isLoading?: boolean
    onClick: (phone: ClientPhoneFieldsFragment) => void
    onRemove?: (phone: ClientPhoneFieldsFragment) => void
}

function PhoneItem({
    phone,
    isPrimary,
    isLoading,
    onClick,
    onRemove,
}: PhoneItemProps) {
    function getPhoneIcon() {
        if (phone.is_mobile) {
            return FaMobile
        }
        return FaPhone
    }

    return (
        <SharedStack row spacing={4}>
            <SharedStack row fit spacing={2}>
                <Tooltip
                    hasArrow
                    label={phone.is_mobile ? 'Mobile Phone' : 'Home Phone'}
                >
                    <Box>
                        <Icon
                            as={getPhoneIcon()}
                            color="gray.400"
                            fontSize="sm"
                        />
                    </Box>
                </Tooltip>
                <SharedStack w="max-content" gap={0}>
                    <Text
                        fontWeight="medium"
                        fontSize="sm"
                        color={phone.is_active ? 'gray.800' : 'gray.400'}
                    >
                        {formatPhoneIfValid(phone.phone)}
                    </Text>
                    <Tooltip hasArrow label={phone.name}>
                        <Text
                            fontSize="10px"
                            color={phone.is_active ? 'gray.600' : 'gray.400'}
                            maxW="120px"
                        >
                            {phone.name}{' '}
                            {!phone.is_active && (
                                <Tag size="xs" colorScheme="gray">
                                    Inactive
                                </Tag>
                            )}
                        </Text>
                    </Tooltip>
                </SharedStack>
            </SharedStack>
            {isPrimary ? (
                <SharedStack row gap="1px">
                    <Tooltip label="Primary" hasArrow>
                        <Box>
                            <Icon as={FaBell} fontSize="sm" color="brand.600" />
                        </Box>
                    </Tooltip>
                    {onRemove && (
                        <Tooltip label="Remove Primary" hasArrow>
                            <Center
                                cursor="pointer"
                                onClick={() => onRemove(phone)}
                                _hover={{
                                    bg: 'rgba(170,170,170,0.1)',
                                }}
                                borderRadius="md"
                                w="18px"
                                h="18px"
                            >
                                <Icon
                                    as={FaX}
                                    fontSize="9px"
                                    color="gray.400"
                                />
                            </Center>
                        </Tooltip>
                    )}
                </SharedStack>
            ) : (
                <Button
                    size="xs"
                    isDisabled={isLoading}
                    colorScheme="brand"
                    variant="subtle"
                    onClick={() => onClick(phone)}
                >
                    Make Primary
                </Button>
            )}
        </SharedStack>
    )
}
