import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { FaMoneyBill } from 'react-icons/fa6'
import { Box, Flex, Text, SimpleGrid, HStack } from '@chakra-ui/layout'

import { eqStringFilter } from '~utils/query-helpers'
import SharedStack from '~components/shared/wrappers/shared-stack'
import {
    AppointmentFieldsFragment,
    PaymentFieldsFragment,
    useQueryPaymentsQuery,
} from '~graphql/generated/graphql'
import PaymentStatusTag from '~components/booking/payments/table/payment-status-tag'
import { format } from 'date-fns'
import GradientDivider from '~components/shared/gradient-divider'
import { formatCents, getCleanEnum } from '~utils/helpers'
import SharedLabel from '~components/shared/form/shared-label'
import IconButtonWithTooltip from '~components/shared/icon-button-with-tooltip'
import { FiExternalLink } from 'react-icons/fi'
import { useUserContext } from '~config/user-context'
import { useClinic } from '~contexts/clinic-context'
import { navigate } from 'gatsby'
import { UserRoutes } from '~config/routes'

interface Props {
    appointment: AppointmentFieldsFragment
}

export default function AppointmentPaymentsSection({ appointment }: Props) {
    const { isAdmin } = useUserContext()
    const { isClinicAdmin } = useClinic()

    const { data: paymentsData } = useQueryPaymentsQuery({
        variables: {
            where: [eqStringFilter('appointment', appointment.id)],
            page: 0,
            pageSize: 10,
            orderBy: {
                column: 'created_at',
                isAscending: false,
            },
        },
    })

    const payments = paymentsData?.queryPayments || []

    if (!payments.length) return null

    return (
        <SharedStack>
            <Flex w="full" justify="space-between" align="center">
                <HStack spacing={4} px={2} pt={1}>
                    <Icon as={FaMoneyBill} color="gray.900" />
                    <Text fontWeight="semibold">Payments</Text>
                </HStack>
                {(isAdmin || isClinicAdmin) && (
                    <IconButtonWithTooltip
                        label="See all payments"
                        icon={<Icon as={FiExternalLink} />}
                        size="sm"
                        variant="subtle"
                        colorScheme="brand"
                        onClick={() => {
                            navigate(UserRoutes.Payments)
                        }}
                    />
                )}
            </Flex>
            <GradientDivider />
            <SharedStack row flexWrap="wrap">
                {payments.map(p => (
                    <PaymentItem key={p.id} payment={p} />
                ))}
            </SharedStack>
        </SharedStack>
    )
}

function PaymentItem({ payment }: { payment: PaymentFieldsFragment }) {
    return (
        <Box
            w="full"
            py={2}
            px={3}
            borderWidth={1}
            borderColor="gray.200"
            borderRadius="lg"
        >
            <SimpleGrid columns={4} columnGap={2} alignItems="center">
                <SharedLabel fontSize="xs">Client</SharedLabel>
                <SharedLabel fontSize="xs">Amount Paid</SharedLabel>
                <SharedLabel fontSize="xs">Status</SharedLabel>
                <SharedStack align="flex-end" spacing={0}>
                    <SharedLabel fontSize="xs">Date</SharedLabel>
                </SharedStack>
                <Text fontSize="sm" fontWeight="semibold">
                    {payment.client?.first_name} {payment.client?.last_name}
                </Text>
                <Text fontWeight="medium" fontSize="sm">
                    {formatCents(payment.amount)}
                </Text>
                <HStack>
                    <PaymentStatusTag size="sm" status={payment.status} />
                    <Text fontSize="xs" fontWeight="semibold">
                        {getCleanEnum(payment.status)}
                    </Text>
                </HStack>
                <SharedStack align="flex-end" spacing={0}>
                    <Text fontSize="xs" fontWeight="medium">
                        {format(payment.date, 'p')}
                    </Text>
                    <Text fontSize="xs">{format(payment.date, 'PPP')}</Text>
                </SharedStack>
            </SimpleGrid>
        </Box>
    )
}
