import React from 'react'
import { Text } from '@chakra-ui/layout'

import SharedStack from '~components/shared/wrappers/shared-stack'
import SharedHelpText from '~components/shared/form/shared-help-text'
import SettingSwitch from '../form/setting-switch'
import SettingSelect from '../form/setting-select'
import { useCommunicationSettingsContext } from './communication-settings-context'
import {
    writebackBufferOptions,
    writebackBeforeApptOptions,
    getMinutesFromMap,
} from '../constants'

export default function WritebackSettings() {
    const {
        isWritebackEnabled,
        setIsWritebackEnabled,
        shouldDailyWriteback,
        setShouldDailyWriteback,
        // usePdfForWriteback,
        // setUsePdfForWriteback,
        writebackBufferMinutes,
        setWritebackBufferMinutes,
        writebackBeforeApptMins,
        setWritebackBeforeApptMins,
        shouldWritebackOnSend,
        setShouldWritebackOnSend,
        shouldWritebackOnReceive,
        setShouldWritebackOnReceive,
        shouldWritebackNotifs,
        setShouldWritebackNotifs,
        shouldUseAiWriteback,
        setShouldUseAiWriteback,
    } = useCommunicationSettingsContext()

    enum WritebackType {
        Daily = 'Daily',
        Instant = 'Instant',
        Dynamic = 'Dynamic',
    }

    const writebackTypes = Object.values(WritebackType)

    // Update the initial type getter to return the full type object
    const getInitialWritebackType = (): WritebackType => {
        if (shouldDailyWriteback) return writebackTypes[0]
        if (shouldWritebackOnSend || shouldWritebackOnReceive)
            return writebackTypes[1]
        return writebackTypes[2]
    }

    const [writebackType, setWritebackType] = React.useState<WritebackType>(
        getInitialWritebackType(),
    )

    // Update the handler to work with the full type object
    const handleWritebackTypeChange = (val: string) => {
        const newType = writebackTypes.find(t => t === val)
        if (!newType) return

        setWritebackType(newType)
        setShouldDailyWriteback(newType === WritebackType.Daily)
        setShouldWritebackOnSend(newType === WritebackType.Instant)
        setShouldWritebackOnReceive(newType === WritebackType.Instant)
        setShouldUseAiWriteback(newType === WritebackType.Dynamic)

        if (newType === WritebackType.Dynamic) {
            setWritebackBufferMinutes(2880)
            setWritebackBeforeApptMins(60)
        } else {
            setWritebackBufferMinutes(-1)
            setWritebackBeforeApptMins(-1)
        }
    }

    return (
        <SharedStack spacing={4}>
            <SettingSwitch
                label="Enable communication writeback to the PIMS?"
                isChecked={isWritebackEnabled}
                onChange={e => setIsWritebackEnabled(e.target.checked)}
            />
            {isWritebackEnabled && (
                <>
                    <SettingSelect
                        label="Writeback Type"
                        value={writebackType}
                        setValue={handleWritebackTypeChange}
                        options={writebackTypes}
                        moreInfo={
                            <>
                                <Text fontSize={'sm'} color="gray.500">
                                    The writeback type determines when to write
                                    messages back into your PIMS.
                                </Text>
                                <Text fontSize={'sm'} color="gray.500">
                                    - <strong>"Daily"</strong> Messages are
                                    written back once per day, and grouped
                                    together by day.
                                </Text>
                                <Text fontSize={'sm'} color="gray.500">
                                    - <strong>"Instant"</strong> Messages are
                                    written back immediately when sent or
                                    received.
                                </Text>
                                <Text fontSize={'sm'} color="gray.500">
                                    - <strong>"Dynamic"</strong> Messages are
                                    written back dynamically based on how long
                                    it's been since the last message, if any
                                    appointments are coming up, and if the
                                    conversation seems to be complete.
                                </Text>
                            </>
                        }
                    />

                    {writebackType === WritebackType.Daily && (
                        <SharedHelpText fontSize="sm" mb={0}>
                            A daily writeback will collect communications
                            throughout the day and write them back once per day.
                            This overrides other automated writeback settings.
                        </SharedHelpText>
                    )}

                    {writebackType === WritebackType.Instant && (
                        <>
                            <SettingSwitch
                                label="Instantly write back messages when sent?"
                                isChecked={shouldWritebackOnSend}
                                isDisabled={!isWritebackEnabled}
                                onChange={e =>
                                    setShouldWritebackOnSend(e.target.checked)
                                }
                            />
                            <SettingSwitch
                                label="Instantly write back messages when received?"
                                isChecked={shouldWritebackOnReceive}
                                isDisabled={!isWritebackEnabled}
                                onChange={e =>
                                    setShouldWritebackOnReceive(
                                        e.target.checked,
                                    )
                                }
                            />
                        </>
                    )}

                    {writebackType === WritebackType.Dynamic && (
                        <>
                            <SettingSelect
                                label="How long to wait before writing back communications?"
                                value={
                                    writebackBufferOptions[
                                        writebackBufferMinutes
                                    ]
                                }
                                setValue={(val: string) => {
                                    setWritebackBufferMinutes(
                                        getMinutesFromMap(
                                            val,
                                            writebackBufferOptions,
                                        ),
                                    )
                                }}
                                options={Object.values(writebackBufferOptions)}
                                isDisabled={!isWritebackEnabled}
                                moreInfo={
                                    <SharedHelpText fontSize="sm" mb={0}>
                                        If no messages have been sent or
                                        received after this amount of time,
                                        messages will be written back to the
                                        PIMS
                                    </SharedHelpText>
                                }
                            />
                            <SettingSelect
                                label="When to write back appointment-related communications?"
                                value={
                                    writebackBeforeApptOptions[
                                        writebackBeforeApptMins
                                    ]
                                }
                                setValue={(val: string) => {
                                    setWritebackBeforeApptMins(
                                        getMinutesFromMap(
                                            val,
                                            writebackBeforeApptOptions,
                                        ),
                                    )
                                }}
                                options={Object.values(
                                    writebackBeforeApptOptions,
                                )}
                                isDisabled={!isWritebackEnabled}
                                moreInfo={
                                    <SharedHelpText fontSize="sm" mb={0}>
                                        Unwritten communications will be written
                                        back to the PIMS at this point before
                                        the appointment
                                    </SharedHelpText>
                                }
                            />
                            <SettingSwitch
                                label="Use AI to decide to write back messages?"
                                isChecked={shouldUseAiWriteback}
                                isDisabled={!isWritebackEnabled}
                                onChange={e =>
                                    setShouldUseAiWriteback(e.target.checked)
                                }
                                moreInfo={
                                    <SharedHelpText fontSize="sm" mb={0}>
                                        When enabled, AI will be used to decide
                                        whether or not to writeback messages.
                                    </SharedHelpText>
                                }
                            />
                        </>
                    )}

                    <SettingSwitch
                        label="Write back system notification messages?"
                        isChecked={shouldWritebackNotifs}
                        isDisabled={!isWritebackEnabled}
                        onChange={e =>
                            setShouldWritebackNotifs(e.target.checked)
                        }
                    />
                </>
            )}
        </SharedStack>
    )
}
