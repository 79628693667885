import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { LocalStorageKeys } from '~config/constants'

// Strongly typed generic for the local storage hook
export function useLocalStorage<T>(
    key: LocalStorageKeys,
    defaultValue: T,
): [T, Dispatch<SetStateAction<T>>, () => void, () => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key)
            // Parse JSON stored item or return defaultValue if null
            return item ? (JSON.parse(item) as T) : defaultValue
        } catch (error) {
            console.error(`Failed to parse localStorage key "${key}":`, error)
            return defaultValue
        }
    })

    // Update localStorage whenever storedValue changes
    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue))
        } catch (error) {
            console.error(`Failed to set localStorage key "${key}":`, error)
        }
    }, [key, storedValue])

    // Remove the item entirely from local storage
    const removeItem = () => {
        try {
            window.localStorage.removeItem(key)
            setStoredValue(defaultValue)
        } catch (error) {
            console.error(`Failed to remove localStorage key "${key}":`, error)
        }
    }

    // Reset to the default value (helpful if you don't want to remove it entirely)
    const resetItem = () => {
        setStoredValue(defaultValue)
    }

    return [storedValue, setStoredValue, removeItem, resetItem]
}
