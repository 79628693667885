import { Stack, StackProps } from '@chakra-ui/react'
import React from 'react'

export interface SharedStackProps extends StackProps {
    row?: boolean
    fit?: boolean
    spaced?: boolean
}

export default function SharedStack({
    row = false,
    fit = false,
    spaced = false,
    children,
    ...props
}: SharedStackProps) {
    return (
        <Stack
            w={fit ? 'fit-content' : 'full'}
            justify={spaced && row ? 'space-between' : 'flex-start'}
            align={row ? 'center' : 'flex-start'}
            direction={row ? 'row' : 'column'}
            {...props}
        >
            {children}
        </Stack>
    )
}
