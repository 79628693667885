import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { BsStripe } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'

import { StripeAccountInfoFieldsFragment } from '~graphql/generated/graphql'
import { FaTriangleExclamation } from 'react-icons/fa6'

interface Props {
    accountInfo: StripeAccountInfoFieldsFragment
}

export default function StripeDashboardActionButton({ accountInfo }: Props) {
    const {
        onboarding_link: onboardingLink,
        login_link: loginLink,
        is_onboarded: isOnboarded,
        requirements,
        future_requirements,
    } = accountInfo

    const link = isOnboarded ? loginLink : onboardingLink

    return (
        <a
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
        >
            <Button
                bg="white"
                color="gray.900"
                borderWidth={1}
                borderColor="gray.200"
                shadow="blue.lg"
                pl={2}
                transition="0.2s"
                _hover={{ bg: 'gray.50', shadow: 'blue.xl' }}
                leftIcon={
                    !!future_requirements?.currently_due?.length ? (
                        <Icon
                            as={FaTriangleExclamation}
                            color="yellow.500"
                            fontSize="2xl"
                            mr={2}
                        />
                    ) : (
                        <Icon
                            as={BsStripe}
                            color="#008cdd"
                            fontSize="2xl"
                            mr={2}
                        />
                    )
                }
                rightIcon={<Icon as={FiExternalLink} ml={2} color="gray.900" />}
            >
                {getButtonText({
                    isOnboarded,
                    requirements,
                    future_requirements,
                })}
            </Button>
        </a>
    )
}

function getButtonText({
    isOnboarded,
    requirements,
    future_requirements,
}: {
    isOnboarded: boolean
    requirements: StripeAccountInfoFieldsFragment['requirements']
    future_requirements: StripeAccountInfoFieldsFragment['future_requirements']
}) {
    const { pending_verification, currently_due } = requirements || {}
    const {
        currently_due: futureCurrentlyDue,
        pending_verification: futurePendingVerifications,
    } = future_requirements || {}

    if (!!futurePendingVerifications?.length) {
        return 'Pending verification from Stripe'
    } else if (!!futureCurrentlyDue?.length) {
        return 'Action required in your Stripe dashboard'
    } else if (!isOnboarded) {
        if (!!pending_verification?.length) {
            if (!!currently_due?.length) {
                return 'Finish setting up your account'
            }
            return 'Pending verification from Stripe'
        }

        return 'Get set up with Stripe'
    }

    return 'Go to your Stripe dashboard'
}
